import React, { Component } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import {
  FaChevronDown,
  FaChevronUp,
  FaCog,
  FaPlus,
  FaSyncAlt,
  FaCircle,
  FaTimes,
  FaRocket,
  FaEye,
  FaEyeSlash,
} from 'react-icons/fa'

import { connect } from 'react-redux'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import {
  BYOC_TARGET,
  CLUSTER_HEALTH_LIVE,
  CLUSTER_HEALTH_CACHED,
  CLUSTER_HEALTH_UNREACHABLE,
  KUBESAIL_LAST_USED_CONTEXT_KEY,
} from '../config'
import { setCurrentContext, filterNamespace } from '../util'
import { ErrorBoundary, FallbackComponent } from '../util/ErrorBoundary'

const UsageIndicator = dynamic(() => import('../components/UsageIndicator'), { ssr: false })
const Pill = dynamic(() => import('../components/Pill'))
const Button = dynamic(() => import('../components/Button'))
const RadioItem = dynamic(() => import('./RadioItem'))

class ContextSelector extends Component {
  state = {
    contextsExpanded: false,
    feedbackOpen: false,
    showingInternal: [],
    collapsed: [],
  }

  componentDidMount = () => {
    this._isMounted = true
  }

  componentDidUpdate = () => {
    if (window.innerWidth <= 720) {
      if (!this.state.contextsExpanded) {
        document.body.style.overflow = 'visible'
      } else {
        document.body.style.overflow = 'hidden'
      }
    } else {
      document.body.style.overflow = 'visible'
    }
  }

  handleBodyClick = e => {
    if (
      (this.clustersListNode && this.clustersListNode.contains(e.target)) ||
      (this.dropdownNode && this.dropdownNode.contains(e.target))
    ) {
      return
    }
    document.body.removeEventListener('click', this.handleBodyClick)
    if (this._isMounted) {
      this.setState({ contextsExpanded: false })
    }
  }

  openContextSelector = (e, what) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ contextsExpanded: !this.state.contextsExpanded }, () => {
      document.body.addEventListener('click', this.handleBodyClick)
    })
    window.setTimeout(() => document.body.addEventListener('click', this.handleBodyClick), 100)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  renderNamespace = (namespace, address) => {
    let context = this.props.profile.currentContext
    if (this.props.namespace && this.props.clusterAddress) {
      context = { namespace: this.props.namespace, address: this.props.clusterAddress }
    }
    const currentContext = context.namespace === namespace.name && context.address === address
    let quota = namespace ? namespace.resourceQuota : null
    if (currentContext) quota = (this.props.docs.find(d => d.kind === 'ResourceQuota') || {}).status
    return (
      <div key={namespace.name + '|' + address}>
        <RadioItem
          checked={currentContext}
          className="context"
          onClick={() => {
            if (this.props.onChange) {
              this.props.onChange({ namespace: namespace.name, clusterAddress: address })
            } else if (!currentContext) {
              setCurrentContext(namespace.name, address)
            }
            this.setState({ contextsExpanded: false })
          }}
        >
          <div className="namespace">
            <div className="namespaceName">{namespace.name}</div>
          </div>
          <div className="namespace-note">
            {namespace.name.startsWith('kube-') ? (
              <Pill
                size="small warn"
                title="This is an internal Kubernetes namespace - it is not recommended for app use!"
              >
                system
              </Pill>
            ) : null}
          </div>
          <div className="usage">
            {quota ? (
              <>
                <UsageIndicator
                  requestUsed={get(quota, "used['requests.memory']", 0)}
                  requestTotal={quota.hard['requests.memory']}
                  used={get(quota, "used['limits.cpu']", 0)}
                  total={quota.hard['limits.cpu']}
                  label="CPU"
                />
                <UsageIndicator
                  requestUsed={get(quota, "used['requests.memory']", 0)}
                  requestTotal={quota.hard['requests.memory']}
                  used={get(quota, "used['limits.memory']", 0)}
                  total={quota.hard['limits.memory']}
                  label="RAM"
                />
              </>
            ) : null}
          </div>
        </RadioItem>
      </div>
    )
  }

  renderClusters = () => {
    if (!this.props.profile) {
      return null
    }

    const { showingInternal } = this.state
    const { profile, namespace, clusterAddress, hideNamespaces, clusterSelect } = this.props

    let context = profile.currentContext
    if (namespace && clusterAddress) {
      context = { namespace, clusterAddress }
    }

    let lastUsedContexts = []
    try {
      lastUsedContexts = JSON.parse(window.localStorage.getItem(KUBESAIL_LAST_USED_CONTEXT_KEY))
    } catch {}
    if (!Array.isArray(lastUsedContexts)) lastUsedContexts = []

    return [...this.props.profile.clusters]
      .sort((a, b) => {
        if (a.address === profile.currentContext.address) return -1
        else if (
          lastUsedContexts.findIndex(c => c.address === a.address) <
          lastUsedContexts.findIndex(c => c.address === b.address)
        )
          return -1
        else return 1
      }) // Always sort the current context on top
      .filter(c => c.verified)
      .map(cluster => {
        const { role, friendlyName, address, namespaces, health } = cluster
        const sortedNamespaces = sortBy(namespaces, 'name')
        const simpleNamespaces = sortedNamespaces.filter(filterNamespace(true)).sort((a, b) => {
          if (a.name === profile.currentContext.namespace) return -2
          if (
            lastUsedContexts.findIndex(c => c.address === address && c.namespace === a.name) <
            lastUsedContexts.findIndex(c => c.address === address && c.namespace === b.name)
          )
            return -1
          return a.resourceQuota && !b.resourceQuota ? (a.name > b.name ? -1 : 1) : 1
        })

        const systemNamespaces = sortedNamespaces
          .filter(filterNamespace(false))
          .filter(ns => !ns.tier)

        let title, className, StatusIcon
        if (!health) {
          title = 'Fetching Namespaces'
          className = 'status-pending spin'
          StatusIcon = FaSyncAlt
        }
        if (!role || health === CLUSTER_HEALTH_LIVE) {
          title = 'Cluster is Online'
          className = 'status-online'
          StatusIcon = FaCircle
        } else if (health === CLUSTER_HEALTH_CACHED) {
          title = 'Cluster Results Cached - Your cluster may be offline'
          className = 'status-offline'
          StatusIcon = FaCircle
        } else if (health === CLUSTER_HEALTH_UNREACHABLE) {
          title = 'Cluster is Unreachable - We were unable to get the namespaces for this cluster'
          className = 'status-unreachable'
          StatusIcon = FaTimes
        }
        const isOpen = this.state.selectedCluster === address

        const CollapseIcon = isOpen ? FaEyeSlash : FaEye

        return (
          <div
            key={address}
            className={`cluster ${clusterSelect ? 'cluster-select' : ''}`}
            onClick={() => {
              if (!clusterSelect) return
              clusterSelect(cluster)
              this.setState({ contextsExpanded: false })
            }}
          >
            <div className="cluster-contents">
              <div className="cluster-contents-info">
                <div className="friendly-name">
                  <div>
                    <StatusIcon className={`status-circle ${className}`} title={title} />
                  </div>
                  <div className="truncate">{friendlyName}</div>
                </div>
                <div className="address">{address}</div>
                {this.props.profile.clusters.length > 1 ? (
                  <div
                    className="collapse"
                    onClick={() => {
                      this.setState({
                        selectedCluster: this.state.selectedCluster === address ? null : address,
                      })
                    }}
                  >
                    <CollapseIcon className="collapse" />
                  </div>
                ) : null}
                <Link
                  href={`/clusters/${encodeURIComponent(friendlyName)}/${
                    role === 'admin' ? 'users' : 'namespaces'
                  }`}
                  onClick={() => this.setState({ contextsExpanded: false })}
                >
                  <div>
                    <FaCog className="cluster-settings" />
                  </div>
                </Link>
              </div>
              {!isOpen && hideNamespaces ? null : (
                <div className="contexts">
                  {sortedNamespaces.length === 0 ? (
                    <div className="offline">No Namespaces. Cluster offline?</div>
                  ) : null}
                  {simpleNamespaces.map(ns => this.renderNamespace(ns, address))}
                  {systemNamespaces.length ? (
                    showingInternal.includes(address) ||
                    (filterNamespace(false)({ name: context.namespace }) &&
                      context.address === address) ? (
                      <>
                        {systemNamespaces.map(ns => this.renderNamespace(ns, address))}
                        <div className="add-namespace">
                          <Link
                            onClick={() => {
                              this.setState({ contextsExpanded: false })
                            }}
                            href={`/clusters/${encodeURIComponent(friendlyName)}/namespaces`}
                          >
                            Add namespace
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() =>
                          this.setState({
                            showingInternal: [...showingInternal, address],
                          })
                        }
                        className="show-internal"
                      >
                        Show All
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </div>
          </div>
        )
      })
  }

  render() {
    const {
      profile,
      namespace,
      clusterAddress,
      statusOnly,
      hideFooter,
      selectedCluster,
      clusterSelect,
      emptyMessage,
    } = this.props
    let context = profile ? profile.currentContext : null
    if (namespace && clusterAddress) {
      context = { namespace, address: clusterAddress }
    }
    if (!profile?.currentContext?.address) {
      return null
    }
    const cluster = (this.props.profile?.clusters || []).find(c => c.address === context.address)
    const selectedClusterData = (this.props.profile?.clusters || []).find(
      c => c.address === selectedCluster
    )

    const friendlyName =
      get(selectedClusterData, 'friendlyName') || emptyMessage || get(cluster, 'friendlyName')
    const upgradeHref = `/clusters/${this.props.profile?.currentContext?.address}/namespaces/${this.props.profile?.currentContext?.namespace}`

    return (
      <ErrorBoundary FallbackComponent={FallbackComponent('context-selector')}>
        <div
          className={`ContextSelector ${statusOnly ? 'status-only' : ''} ${
            this.props.className || ''
          }`}
        >
          <div
            className="current-context-wrap"
            ref={node => (this.dropdownNode = node)}
            onClick={e => this.openContextSelector(e, this.state.contextsExpanded)}
          >
            <div className="current-context">
              {clusterSelect ? (
                <>
                  <div className="namespace">{friendlyName}</div>
                </>
              ) : (
                <>
                  <div
                    className="namespace"
                    style={{
                      fontSize:
                        context.namespace.length > 13
                          ? '0.75rem'
                          : context.namespace.length > 10
                          ? '0.85rem'
                          : '1rem',
                      width:
                        context.namespace.length > 13
                          ? 110
                          : context.namespace.length > 10
                          ? 90
                          : 85,
                    }}
                  >
                    {context.namespace}
                  </div>
                  <div className="address">{friendlyName}</div>
                </>
              )}
            </div>
            {!statusOnly && (
              <div className="selector-icons">
                <FaChevronUp />
                <FaChevronDown />
              </div>
            )}
          </div>
          {this.state.contextsExpanded ? (
            <div className="clusters">
              <div className="clusters-list" ref={node => (this.clustersListNode = node)}>
                {this.renderClusters()}
              </div>
              {hideFooter ? null : (
                <div className="footer">
                  {cluster && cluster.shared ? (
                    <>
                      <div className="footerText translate">Attach your own clusters!</div>
                      <Button
                        href={`${BYOC_TARGET}/${this.props.profile.username}.yaml`}
                        className="small"
                      >
                        <FaPlus />
                        <span>&nbsp;Add Cluster</span>
                      </Button>

                      <Button href={upgradeHref} className="small">
                        <FaRocket />
                        <span>Upgrade</span>
                      </Button>
                    </>
                  ) : (
                    <>
                      <div className="footerText translate">Need a hand managing your cluster?</div>
                      <Button
                        target="_blank"
                        rel="noopener noreferrer"
                        href={'https://kubesail.typeform.com/to/lFZF2r'}
                        className="small"
                      >
                        <FaPlus />
                        <span>Contact us!</span>
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
          ) : null}
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(({ profile, docs }) => {
  return { profile, docs }
})(ContextSelector)
